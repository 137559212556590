import React, {useState, useEffect, useCallback} from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {formatDistanceToNow} from 'date-fns';
import PerformanceGraph from './components/PerformanceGraph';


function StatCard({icon, value, label}) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (typeof value === 'number') {
            let startValue = 0;
            const step = value / 100; // Adjust for smoother/faster animation
            const timer = setInterval(() => {
                startValue += step;
                if (startValue >= value) {
                    clearInterval(timer);
                    setCount(value);
                } else {
                    setCount(Math.floor(startValue));
                }
            }, 20);

            return () => clearInterval(timer);
        } else {
            setCount(value); // For non-numeric values like avg response time
        }
    }, [value]);

    return (
        <div className="bg-white p-4 rounded-lg shadow-md shadow-[#2e2f773b] text-center">
            <span className="text-2xl mb-2 inline-block">{icon}</span>
            <h2 className="text-3xl font-bold mb-1 text-[#2e2f77]">{count}</h2>
            <p className="text-gray-600">{label}</p>
        </div>
    );
}

function QuestionCard({text, time, points, answeredAt}) {
    const timeAgo = formatDistanceToNow(new Date(answeredAt), {addSuffix: true});

    return (
        <div className="bg-white p-4 rounded-lg shadow-md shadow-[#2e2f773b] flex flex-col justify-between h-32">
            <h3 className="text-lg font-semibold mb-2 line-clamp-2 text-[#2e2f77]">{text}</h3>
            <div>
                <p className="text-blue-600 font-bold mb-1">+{points} points</p>
                <p className="text-sm text-gray-500">Answered {timeAgo}</p>
            </div>
        </div>
    );
}

function App() {
    const [stats, setStats] = useState({
        questionsAsked: 0,
        questionsAnswered: 0,
        pointsGained: 0,
        avgResponseTime: '0s'
    });
    const [displayedQuestions, setDisplayedQuestions] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        console.log('Fetching data...'); // Debug log
        try {
            const response = await fetch('/questions.json');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setStats(data.stats);
            setAllQuestions(data.questions);
            setHistoricalData(data.historicalData);
            setLoading(false);
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch data');
            setLoading(false);
        }
    }, []);


    // Fetch data every 5 minutes
    useEffect(() => {
        fetchData(); // Initial fetch
        const interval = setInterval(fetchData, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, [fetchData]);

    // Update displayed questions every 5 seconds
    useEffect(() => {
        const updateDisplayedQuestions = () => {
            if (allQuestions.length === 0) return;

            setDisplayedQuestions(prevQuestions => {
                const availableQuestions = allQuestions.filter(q =>
                    !prevQuestions.some(pq => pq.id === q.id)
                );

                let newQuestion;
                if (availableQuestions.length > 0) {
                    newQuestion = availableQuestions[Math.floor(Math.random() * availableQuestions.length)];
                } else {
                    // If all questions have been used, pick a random one that's not in the current display
                    const unusedQuestions = allQuestions.filter(q =>
                        !prevQuestions.slice(0, 8).some(pq => pq.id === q.id)
                    );
                    newQuestion = unusedQuestions[Math.floor(Math.random() * unusedQuestions.length)];
                }

                return [
                    {...newQuestion, answeredAt: new Date().toISOString()},
                    ...prevQuestions.slice(0, 8)
                ];
            });
        };

        // Initial update
        if (allQuestions.length > 0 && displayedQuestions.length === 0) {
            setDisplayedQuestions(
                allQuestions.slice(0, 9).map(q => ({
                    ...q,
                    answeredAt: new Date().toISOString()
                }))
            );
        }

        const interval = setInterval(updateDisplayedQuestions, 5000);
        return () => clearInterval(interval);
    }, [allQuestions]);

    if (loading) return <div className="text-center mt-8">Loading...</div>;
    if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

    return (
        <div>
            <section className="bg-white">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-16 lg:pb-8 lg:px-12">
                    <a href="#"
                       className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-[#2e2f77db] rounded-full"
                       role="alert">
                        <span className="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">New</span>
                        <span className="text-sm font-medium text-white">Earn rewards to answer questions!</span>
                        <svg className="ml-2 w-5 h-5" fill="white" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clip-rule="evenodd"></path>
                        </svg>
                    </a>
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">DigNow Latest Statistics</h1>
                    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">See how fast the community earns rewards by participating on our platform</p>
                    <div
                        className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                        <a href="#"
                           className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-[#2e2f77db] hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
                            Earn now
                            <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                      clip-rule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="#"
                           className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">
                            <svg className="mr-2 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                            </svg>
                            Watch video
                        </a>
                    </div>

                </div>
            </section>
            <div className="container mx-auto p-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                    <StatCard icon="?" value={stats.questionsAsked} label="Questions Asked"/>
                    <StatCard icon="✓" value={stats.questionsAnswered} label="Questions Answered"/>
                    <StatCard icon="🏆" value={stats.pointsGained} label="Points Gained"/>
                    <StatCard icon="⏱" value={stats.avgResponseTime} label="Avg. Response Time"/>
                </div>
                <div className="mb-8">
                    <PerformanceGraph historicalData={historicalData}/>
                </div>
                <div className="grid grid-cols-3 gap-4">
                    <TransitionGroup component={null}>
                        {displayedQuestions.map((question, index) => (
                            <CSSTransition key={question.id} timeout={500} classNames="fade">
                                <div style={{gridRow: Math.floor(index / 3) + 1, gridColumn: (index % 3) + 1}}>
                                    <QuestionCard
                                        text={question.text}
                                        time={question.time}
                                        points={question.points}
                                        answeredAt={question.answeredAt}
                                    />
                                </div>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
            </div>
        </div>
    );
}

export default App;