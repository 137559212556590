import React, {useEffect, useRef} from 'react';
import ApexCharts from 'apexcharts';

function PerformanceGraph({historicalData}) {
    const chartRef = useRef(null);

    useEffect(() => {
        const options = {
            chart: {
                height: 300,
                type: "area",
                fontFamily: "Inter, sans-serif",
                dropShadow: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                },
            },
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.55,
                    opacityTo: 0,
                    shade: "#1C64F2",
                    gradientToColors: ["#1C64F2"],
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 6,
            },
            grid: {
                show: false,
                strokeDashArray: 4,
                padding: {
                    left: 2,
                    right: 2,
                    top: 0
                },
            },
            series: [
                {
                    name: "Questions Answered",
                    data: historicalData.map(item => item.questionsAnswered),
                    color: "#7C3AED",
                },
                {
                    name: "Questions Asked",
                    data: historicalData.map(item => item.questionsAsked),
                    color: "#2563EB",
                },
                {
                    name: "Points Earned",
                    data: historicalData.map(item => item.pointsEarned),
                    color: "#DB2777",
                }
            ],
            xaxis: {
                categories: historicalData.map(item => item.date),
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false
            }
        };

        if (chartRef.current && typeof ApexCharts !== 'undefined') {
            const chart = new ApexCharts(chartRef.current, options);
            chart.render();

            return () => {
                chart.destroy();
            };
        }
    }, [historicalData]);

    const latestData = historicalData[historicalData.length - 1];
    const previousData = historicalData[historicalData.length - 2];
    const percentageChange = ((latestData.questionsAnswered - previousData.questionsAnswered) / previousData.questionsAnswered * 100).toFixed(1);

    return (
        <div className="w-full bg-white rounded-lg shadow p-4 md:p-6">
            <div className="flex justify-between">
                <div>
                    <h5 className="leading-none text-3xl font-bold pb-2 text-purple-600">{latestData.questionsAnswered}</h5>
                    <p className="text-base font-normal text-gray-500">Questions answered in total</p>
                </div>
                <div>
                    <h5 className="leading-none text-3xl font-bold pb-2 text-blue-600">{latestData.questionsAsked}</h5>
                    <p className="text-base font-normal text-gray-500">Questions asked in total</p>
                </div>
                <div>
                    <h5 className="leading-none text-3xl font-bold pb-2 text-pink-600">{latestData.pointsEarned}</h5>
                    <p className="text-base font-normal text-gray-500">Points earned in total</p>
                </div>
                <div
                    className={`flex items-center px-2.5 py-0.5 text-base font-semibold ${
                        percentageChange >= 0 ? 'text-green-500' : 'text-red-500'
                    } text-center`}>
                    {percentageChange}%
                    <svg className="w-3 h-3 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 10 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M5 13V1m0 0L1 5m4-4 4 4"/>
                    </svg>
                </div>
            </div>
            <div id="area-chart" ref={chartRef}></div>
            <div
                className="grid grid-cols-1 items-center border-gray-200 border-t justify-between">
            </div>
        </div>
    );
}

export default PerformanceGraph;